<template>
    <div class="row-item add-item">
        <div class="column-icon">
            <icon-plus />
        </div>
        <div class="column-input">
            <input type="text" :placeholder="$t('dashboard.placeholder_add_task')" @keyup.enter="storeTask" v-model="name"/>
        </div>
        <div class="column-input-date" v-if="extraInfo.includes('date')">
            <div class="deadline-calendar" :class="{bottom: index < 2, top: index >= 2}">
                <InputCalendarPicker :data="date" @updateDate="(dataResponse) => { date = dataResponse.value }" :key="`input-calendar-${userId}`"/>
            </div>
        </div>
        <div class="column-input-flag" v-if="extraInfo.includes('priority')">
            <InputPriorityCheck :data="priority" @updatePriority="(dataResponse) => { priority = dataResponse.value }" :key="`input-priority-${userId}`" />
        </div>
        <div class="column-input-avatar" v-if="extraInfo.includes('user')">
            <InputUserSelect :data="responsibleId ? responsibleId : ''" :optionsUsers="$parent.optionsUsers" @updateUser="(dataResponse) => { responsibleId = dataResponse.value }" :key="`input-select-${userId}`"/>
        </div>
        <div class="column-button">
            <button class="btn-tbf blue center" @click="storeTask">
                <div class="text">{{ $t('general.add') }}</div>
            </button>
        </div>

        <div class="row-info-action" v-if="name">
            <div class="text">{{ $t('meeting_session.remainder_add_task') }}</div>
        </div>
    </div>
</template>

<script>
    import IconTask from '@/components/Icons/Task'
    import IconPlus from '@/components/Icons/Plus'
    import InputCalendarPicker from '@/components/ComponentsTask/CalendarPicker'
    import InputPriorityCheck from '@/components/ComponentsTask/PriorityCheck'
    import InputUserSelect from '@/components/ComponentsTask/UserSelect'
    import InputName from '@/components/ComponentsTask/InputName'

    export default {
        components: {
            IconTask,
            IconPlus,
            InputCalendarPicker,
            InputPriorityCheck,
            InputUserSelect,
            InputName
        },
        props: {
            userId: {
                type: Number,
                default: 0
            },
            extraInfo: {
                type: Array,
                default: []
            },
            index: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                name: '',
                date: '',
                priority: '',
                responsibleId: ''
            }
        },
        methods: {
            storeTask() {
                if (this.name.length > 0) {
                    var formSend = {
                        name: this.name
                    };
                    
                    if(this.extraInfo.includes('date')) {
                        formSend.date = this.date
                    }

                    if(this.extraInfo.includes('priority')) {
                        formSend.priority = this.priority
                    }

                    if(this.extraInfo.includes('user')) {
                        formSend.responsibleId = this.responsibleId
                    }

                    this.$emit('store-task', formSend);

                    this.name = '';
                    this.date = '';
                    this.priority = '';
                    this.responsibleId = '';
                }
            }
        }
    }
</script>