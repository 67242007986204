<template>
    <FunctionalCalendar
        ref="CalendarDateOfEmployment"
        v-model="deadline"
        class="calendar-tbf"
        :configs="calendarConfigs"
        @dayClicked="selectDeadline"
    >
        <template v-slot:datePickerInput="props">
            <div class="vfc-single-input custom-placeholder-picker" v-if="!deadline.selectedDate"><icon-calendar class="icon"/></div>
            <div class="selected-deadline" v-else>{{ moment(props.selectedDate, 'D/M/YYYY').format('DD MMM') }}</div>
        </template>
    </FunctionalCalendar>
</template>

<script>
import IconCalendar from '@/components/Icons/Calendar';

export default {
    components: {
        IconCalendar,
    },
    props: {
        data: String
    },
    data() {
        return {
            calendarConfigs: {
                isDatePicker: true,
                isDateRange: false,
                isModal: true,
                isAutoCloseable: true,
                changeMonthFunction: true,
                changeYearFunction: true,
                disabledDates: ['beforeToday']
            },
            deadline: {
                currentDate: moment(),
                selectedDate: '',
                multipleDateRange: [],
                selectedDates: [],
                selectedDateTime: false,
                selectedDatesItem: "",
                selectedHour: "00",
                selectedMinute: "00",
                dateRange: {end: '',start: ''}
            }
        }
    },
    watch: {
        data(newValue) {
            if(newValue != '' && newValue) {
                this.deadline.selectedDate = moment(newValue, "YYYY-MM-DD").format('D/M/YYYY');
            } else {
                this.deadline.selectedDate = '';
            }
        }
    },
    mounted() {
        if(this.data != '' && this.data) {
            this.deadline.selectedDate = moment(this.data, "YYYY-MM-DD").format('D/M/YYYY');
        }
    },
    methods: {
        selectDeadline(deadlineData){
            this.deadline.selectedDate = deadlineData.date;
            this.$emit('updateDate', {type: 'date', value: moment(deadlineData.date, "D/M/YYYY").format('YYYY-MM-DD') });
        },
    }
}
</script>