<template>
    <div class="filter-dropdown-header icon-dropdown" id="boxDropdownPriority">
        <button id="dropdownPriority" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="icon-flag">
                <icon-flag :class="`icon ${levelOfPriority.color ? levelOfPriority.color : ''}`"/>
            </div>
        </button>
        <div class="dropdown-menu icon-options" aria-labelledby="dropdownPriority">
            <template v-for="option in optionsPriority">
                <div class="dropdown-item" :class="{selected: levelOfPriority == option}" @click="selectPriority(option)">
                    <div class="icon">
                        <icon-flag class="icon" :class="option.color"/>
                    </div>
                    <div class="text">{{ option.name }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import IconFlag from '@/components/Icons/Flag'

export default {
    components: {
        IconFlag,
    },
    props: {
        data: String
    },
    data() {
        return {
            optionsPriority: [
                {key: 'urgent', name: 'Urgent', color: 'red'},
                {key: 'important', name: 'Important', color: 'yellow' },
                {key: 'normal', name: 'Normal', color: 'blue' }
            ],
            levelOfPriority: '',
        }
    },
    watch: {
        data(newValue) {
            if(newValue != '' && newValue) {
                this.levelOfPriority = this.optionsPriority.find(el => el.key == newValue);
            } else {
                this.levelOfPriority = '';
            }
        }
    },
    mounted() {
        if(this.data != '' && this.data) {
            this.levelOfPriority = this.optionsPriority.find(el => el.key == this.data);
        }
    },
    methods: {
        selectPriority(priority){
            this.levelOfPriority = this.levelOfPriority == priority ? '' : priority
            this.$emit('updatePriority', {type: 'priority', value: this.levelOfPriority ? this.levelOfPriority.key : '' });
        },
    }
}
</script>