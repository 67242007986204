<template>
     <div class="row-item promise-row">
        <div class="column-icon status-promise" v-bind:class="[
            promise.daily_promise_status, {
                completed: promise.today_promise ? promise.today_promise.status == 'completed' : false,
                not_completed: promise.today_promise ? promise.today_promise.status == 'not_completed' : false,
                warning: promise.today_promise ? promise.today_promise.status == 'pending' : false
            }]">
            <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf"
                :delay="{ show: 200, hide: 0 }">
                <icon-promise />

                <template slot="popover">
                    <div class="title">{{ $t('meeting_session.status_promise') }}</div>
                    <div class="simple-text" v-if="promise.daily_promise_status === 'person_not_working_today'">{{
                        $t('timeline.promise-not-need') }}</div>
                    <div class="simple-text" v-else-if="promise.daily_promise_status === 'promise_not_set'">{{
                        $t('timeline.promise-not-set') }}</div>
                    <div class="simple-text"
                        v-else-if="promise.daily_promise_status === 'has_promise' && promise.today_promise.status == 'completed'">
                        {{ $t('promise_objectives.status.completed') }}</div>
                    <div class="simple-text"
                        v-else-if="promise.daily_promise_status === 'has_promise' && promise.today_promise.status == 'not_completed'">
                        {{ $t('promise_objectives.status.not_completed') }}</div>
                    <div class="simple-text"
                        v-else-if="promise.daily_promise_status === 'has_promise' && promise.today_promise.status == 'pending'">
                        {{ $t('promise_objectives.status.pending') }}</div>
                </template>
            </v-popover>
        </div>
        <div class="column-input">
            <div v-if="promise.today_promise" class="text">
                {{ promise.today_promise.name }}
            </div>
            <div v-else class="text">
                {{ $t('meeting_session.no_promise_setted') }}
            </div>
        </div>
        <div class="column-select" :class="{ 'only-read': promise.daily_promise_status != 'promise_not_set' }">
            <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf"
                :delay="{ show: 200, hide: 0 }" v-if="promise.today_promise">
                <div class="text">
                    <span class="label">{{ $t('evaluate_promise.contribute_to') }}</span>
                    <span class="count">{{ $tc('dashboard.count_key_results_involved',
                        promise.today_promise.key_results_names.length) }}</span>
                </div>
                <template slot="popover">
                    <div class="simple-text">
                        <p v-for="key_result in promise.today_promise.key_results_names">- {{ key_result }}</p>
                    </div>
                </template>
            </v-popover>
        </div>
    </div>
</template>

<script>
import IconPromise from '@/components/Icons/Promise'
import IconPlus from '@/components/Icons/Plus'


export default {
    components: {
        IconPromise,
        IconPlus
    },
    props: {
        dataPromises: {
            type: Object,
            required: true
        },
        userId: {
            type: Number,
            required: true
        }
    },
    computed: {
        promise() {
            return this.dataPromises[this.userId];
        },
    },
}
</script>
