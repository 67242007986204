<template>
    <div class="filter-dropdown-header avatars-dropdown" id="boxDropdownAvatars">
        <button id="dropdownAvatars" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="icon">
                <v-popover :disabled="$resize && $mq.below(780)" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="userSelected">
                    <img class="avatar" :src="userSelected.avatar"/>

                    <template slot="popover">
                        <div class="title">{{ userSelected.name }}</div>
                    </template>
                </v-popover>

                <div class="user-circle" v-else>
                    <icon-user />
                </div>
            </div>
        </button>
        
        <div class="dropdown-menu" aria-labelledby="dropdownAvatars">
            <div class="search-filter-dropdown">
                <input type="text" v-model="searchUsers" :placeholder="$t('add_task.choose_responsible')">
            </div>

            <template v-for="user in filteredUsers">
                <div class="dropdown-item" :class="{selected: userSelected == user}" @click="selectResponsible(user)">
                    <img class="avatar" :src="user.avatar"/>
                    <span class="name">{{ user.name }}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import IconUser from '@/components/Icons/User'

export default {
    components: {
        IconUser,
    },
    props: {
        data: Number|String,
        optionsUsers: Array
    },
    data() {
        return {
            userSelected: '',
            searchUsers: ''
        }
    },
    computed: {
        filteredUsers(){
            return getByKeywordFilter(this.optionsUsers, this.searchUsers)
        }
    },
    watch: {
        data(newValue) {
            if(newValue != '' && newValue) {
                this.userSelected = this.optionsUsers.find(el => el.id == newValue);
            } else {
                this.userSelected = '';
            }
        }
    },
    mounted() {
        if(this.data != '' && this.data) {
            this.userSelected = this.optionsUsers.find(el => el.id == this.data);
        }
    },
    methods: {
        selectResponsible(user) {
            this.userSelected = user;
            // this.$emit('updatePriority', this.levelOfPriority ? this.levelOfPriority.key : '');
            this.$emit('updateUser', {type: 'responsible', value: this.userSelected.id });
        },
    }
}

function getByKeywordFilter(list, keyword){
    const search = keyword.trim()

    if (!search.length) return list
    return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
}
</script>