<template>
    <input type="text" :placeholder="placeholder" v-model="valueInput" @input="updateName"/>
</template>

<script>
export default {
    props: {
        data: String,
        placeholder: String
    },
    data() {
        return {
            valueInput: ''
        }
    },
    watch: {
        data(newVal) {
            this.valueInput = newVal;
        }
    },
    mounted() {
        if(this.data != '' && this.data) {
            this.valueInput = this.data;
        }
    },
    methods: {
        updateName(){
            this.$emit('updateName', {type: 'name', value: this.valueInput });
        },
    }
}
</script>